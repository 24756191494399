.running-project {
  display: flex;
  align-items: center;
  border: 1px solid rgba(206, 202, 202, 0.354);
  border-radius: 8px;
  margin: auto;
  flex-wrap: wrap;
  background: #a9a9a912;
}

.operational-project .card-item,
.running-project .card-item {
  width: 170px;
  aspect-ratio: 1/1;
  /* background-color: red; */
  border: 1px solid rgb(182 178 178 / 22%);
  border-radius: 8px;
  margin: 12px;
  font-size: 14px;
  background: white;
  padding: 16px;
  text-align: center;
  font-weight: 700;
}

.card-item p:first-child {
  height: 64px;
  text-align: start;
  font-size: 14px;
}

.operational-project {
  display: flex;
  align-items: center;
  border: 1px solid rgba(182, 178, 178, 0.354);
  border-radius: 8px;
  margin: auto;
  flex-wrap: wrap;
}
.card-item h4 {
  font-size: 20px;
}
.op-card-item .card-item h4 {
  font-size: 16px;
}
.op-card-item p:first-child {
  font-size: 12px;
}
.operational-project .op-card-item {
  border: 1px solid rgba(182, 178, 178, 0.354);
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  background: #a9a9a912;
}

.op-card-item .card-item {
  width: 96px;
  height: 110px;
  margin: 4px;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.operational-project .header {
  font-size: 14px;
  background-color: #309cda;
  color: white;
  padding: 6px;
  /* border-radius: 8px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 14px;
}
